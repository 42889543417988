@import '../../../../styles/responsive.scss';

.po-list {
  padding: 20px 10px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
  }  
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.po-modal-content{
  display: flex;
  flex-direction: column;
  gap: 20px;
  .upload-doc-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;    
  }
}