.foreign-currency-list {
    padding: 20px 0px;
    .cl-header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .left-container {
        display: flex;
        gap: 20px;
      }
    }
}