.subs-mainTabContainer {
  background: #ffffff;
  // border: 1px solid #e1e7ff;
  box-sizing: border-box;
  height: 40px;
  width: 99%;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  align-items: center;
  position: fixed;
  top: 40px;
  z-index: 5;
}

.subs-tab-container {
  border: none;
  width: 100%;
}

.subs-tab-container.subs-tab-container-none-border.css-1gsv261 {
  border: none;
}

.franchise-agreement-container {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  .tab-panel-container {
    padding: 10px;
  }

  .yellow {
    color: yellow;
  }
  .gray {
    color: gray;
  }
  .mainFranchiseDetails {
    margin-top: 2rem;
    .grid-with-0-height {
      height: 0;
    }
    .gray {
      color: gray;
    }
    .red {
      color: red;
    }
    .commentText {
      margin-top: 10px;
      margin-left: 10px;
      width: 95%;
    }
    .franchise-agreement-DetailButton {
      background-color: lightgrey;
      color: white;
      transform: scale(0.9);
    }
    .franchise-agreement-DetailButton-active {
      color: white;
      transform: scale(0.9);
      background-color: #008a52;
    }

    .rotate-roller {
      rotate: -118deg;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      // padding: 10px 0px;
      display: inline-block;
      margin: 10px 0px;
    }
    .address-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .v-divider {
      width: 1px;
      height: 30px;
      border-right: 1px solid black;
    }
    .f-r-2 {
      display: grid;
      grid-template-columns: 1fr 60px;
      height: 20px;
    }
    .g-r-2 {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      height: 20px;
    }
    .mb-1 {
      margin-bottom: 10px;
    }
    .w-80 {
      width: 80%;
    }
    .w-60 {
      width: 60%;
    }
    .w-40 {
      width: 40%;
    }
    .w-100 {
      width: 100%;
    }
    .w-50 {
      width: 50%;
    }
    .w-10 {
      width: 10%;
    }
    .w-20 {
      width: 20%;
    }

    .w-75 {
      width: 75%;
    }
  }
}

.flex-end {
  justify-content: end;
}
.table-height {
  max-height: 15em;
  min-height: 5em;
  max-width: 100%;
  overflow: auto;
}
