/** @format */
@import "../../../common-utilities//styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";
.side-nav-con {
  margin-top: 10px;
  margin-left: 10px;
  .side-nav-header {
    display: flex;
    align-items: center;
    h4 {
      margin-left: 1rem;
    }
  }
  .app-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 31px;
    color: black;
    text-align: center;
    margin-bottom: 10px;
  }

  .subway-current-app-header-container {
    background-color: #ffffff;
    padding: 5px;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
    border: 1px solid #e2e2e2;
    .app-log-out {
      display: flex;
      gap: 10px;
      cursor: pointer;
      padding: 10px;
      position: absolute;
      bottom: 20px;
    }
    .app-log-out:hover {
      background-color: rgba(0, 176, 116, 0.15);
    }
  }
}
