.information-modal{ 
    .item-label-value {
        font-size: medium;
        display: grid;
        grid-template-columns: 1fr 1fr;
        word-break: break-all;
    }
}

.downloadgrid {
    display: grid;
    margin-bottom: 1em;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 2vw;
    .downloadSubGrid {
      display: grid;
      align-items: center;
      flex-direction: column;
      word-break: break-all;
    }
  }
  