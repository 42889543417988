.waiver-details-main-con {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  padding: 2em 1em 2px 1em;
  .header {
    padding-bottom: 0.5em;
    padding-top: 1em;
  }
  .sel-item {
    width: 90%;
  }
  .mr {
    margin-right: 30px;
  }
  .mb {
    margin-bottom: 10px;
  }
  .mr {
    margin-right: 30px;
  }
  .pb10 {
    padding-bottom: 10px;
  }
  .waiver-sel-con {
    display: flex;
    justify-content: space-between;
  }
  .table-con {
    margin-top: 2em;
    max-height: 15em;
    width: 100%;
    overflow: auto;
  }
}
