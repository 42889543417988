.table-cell {
  color: white !important;
  font-size: 12px !important;
  padding: 5px !important;
  line-height: normal !important;
  border: none !important;
}
.bd-table-cell {
  padding: 2px !important;
  font-size: 12px !important;
}
.con-scroll::-webkit-scrollbar-thumb {
  background-color: #c7c9cb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.con-scroll::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  @media only screen and (min-width: 2000) {
    display: none;
  }
}

.con-scroll::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  @media only screen and (min-width: 2000) {
    display: none;
  }
}
.table-container {
  max-height: 23em;
  min-height: 5em;
  width: 100%;
  overflow: auto;
}

.si-pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
