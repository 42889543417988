.lease {
  margin-top: 1rem;
  .input-title-paragrah {
    margin: 0px 0px 0px 0px;
    font-size: small;
    font-weight: lighter;
  }
  .g-r-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 5px;
    height: auto;
  }
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .check {
    margin-left: auto;
    padding: 0px;
    margin-top: -5px;
    margin-right: 25px;
  }
  .flex {
    display: flex;
  }
  .mt {
    margin-top: 20px;
  }
  .Mui-disabled {
    -webkit-text-fill-color: #333333ad !important;
    font-weight: 600;
  }
  background-color: white;
  padding: 2px 2em;
  .title {
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: 700;
  }
  .header {
    padding-bottom: 0px;
  }
  .sel-item {
    width: 90%;
  }
  .total-tax {
    width: 70%;
  }
  .mt10 {
    margin-top: -10px !important;
  }
  .search-p {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .table-con {
    max-height: 7em;
    min-height: 5em;
    width: 100%;
    overflow: auto;
  }
  .lease-section-div {
    width: 95%;
    min-height: 12em;
    max-height: 15em;
    margin-bottom: 1em;
    margin-top: 1em;
    border: 1px solid grey;
    overflow: auto;
  }
}
