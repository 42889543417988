.document-viewer {
  position: relative;
  .dv-download-icon {
    position: absolute;
    right: 0px;
    display: flex;
    justify-content: center;
  }
  .document-viewer-image {
    padding: 10px;
    max-width: 500px;
    margin-top: 20px;
  }
  .document-viewer-pdf {
    .pdf-viewer-container {
      margin-top: 36px;
    }
  }
}
