@import "../../styles/responsive.scss";

.time-line-c {
  min-height: 80px;
  .dot {
    width: 24px;
    height: 24px;
    background: #1d49ae;
    border-radius: 50%;
  }
  .no-line {
    min-width: 500px;
    margin-left: 12px;
    min-height: 140px;
    @include respond-to("small") {
      min-width: auto;
    }
  }
  .line {
    @extend .no-line;
    border-left: 1px solid #1756f3;
  }

  .component {
    padding-left: 20px;
    min-width: 250px;
  }
}
