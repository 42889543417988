@import "../../../common-utilities/styles/variables.scss";
.create-voucher-container {
  margin-top: 1.5rem;
  .details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .expense-date-con {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }
  .voucher-form {
    display: flex;
    justify-content: space-between;
  }
  .file-con {
    display: flex;
  }
  .file-name {
    background-color: $primary;
    color: white;
    width: fit-content;
    padding: 5px;
    margin: 2px;
  }
  .voucher-btn-con {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-end;
    margin-right: 1rem;
  }
}
