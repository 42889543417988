/** @format */
@import "../../../common-utilities/styles/variables.scss";
@import "../../../common-utilities/styles/responsive.scss";
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  .bd-logo {
    width: 180px;
    padding-left: 10px;
    cursor: pointer;
    @include respond-to("small") {
      width: 100px;
      padding-left: 2px;
    }
  }
  .menu {
    display: flex;
  }
  .endLoginContainer {
    display: flex;
    align-items: center;
    .notification-icon {
      margin-right: 1rem;
      .main-notification-icon {
        transform: scale(1.3);
        cursor: pointer;
      }
    }
  }
}
.logo-con {
  display: flex;
  align-items: center;
}
