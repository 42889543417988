// .custom_select__control {
//   min-height: 30px !important;
//   height: 30px !important;
// }

// .custom_select__value-container {
//   margin-top: -4px !important;
// }

// .custom_select__indicators {
//   margin-top: -4px !important;
// }
.notice-date-heading {
  font-weight: lighter;
  padding-bottom: 10px;
}
