.person-store-listing-con {
  background-color: white;
  padding: 1em;
  .search-div {
    margin-top: 1em;
    display: flex;
    align-items: center;
    p {
      color: #008a52;
    }
    .autoComplete {
      width: 100%;
    }
  }
  .table-con {
    margin-top: 1em;
    .table-cell {
      color: white;
    }
  }
}
