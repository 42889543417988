.main-voucher-res-div {
  .voucher-thank-you-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 36px;
      color: #333;
      margin-bottom: 20px;
    }

    .message {
      font-size: 24px;
      color: #666;
      text-align: center;
      max-width: 400px;
    }
    .approval-icon {
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
}
