.waiver-main-con {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  padding: 2em 1em 2px 1em;
  .header {
    padding-bottom: 0px;
    font-weight: 700;
  }
  .sel-item {
    width: 90%;
  }

  .mr {
    margin-right: 30px;
  }

  .input-title {
    margin: 0px;
    font-size: small;
    font-weight: lighter;
  }
  .waiver-listing-con {
    height: 40vh;
    width: 100%;
    overflow: auto;
  }
  .waiver-selection {
    .sel-item {
      width: 80%;
    }
    .wraper-div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
    }
    .snap-shot-div {
      border: 1px solid grey;
      border-radius: 5px;
    }
    .list-p {
      margin: 0px;
      padding: 0.2em;
    }
  }
  .table-con {
    margin-top: 2em;
    max-height: 15em;
    width: 100%;
    overflow: auto;
  }
}
.display-in-row {
  display: flex;
}
