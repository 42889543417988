.balance-card {
  border: 1px solid #dcd9de;
  padding: 10px;
  width: 200px;
  border-radius: 5px;
  background-color: white;
  .physical-balance-title {
    color: #d4c43d;
  }
  .book-balance-title {
    color: #8e2ee8;
  }
  .inprocess-balance {
    color: #e61cba;
  }
}
.approve-all-con {
  border: 1px solid #dcd9de;
  padding: 10px;
  border-radius: 5px;
  width: 50%;
  margin: auto;
  background-color: #f9f9f9;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .approve-btn-con {
    display: flex;
    justify-content: center;
  }
}
.view-comment {
  display: flex;
  justify-content: center;
  table,
  th,
  td {
    border: 1px solid black;
  }
  th,
  td {
    padding: 10px;
  }
}
