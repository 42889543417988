.budget-list {
  padding: 20px 0px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
  }  
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .filter-row{
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
