@import '../../styles/responsive.scss';

$loading-indicator-background: rgba(255, 255, 255, 0.9);

.loading-indicator {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: $loading-indicator-background;
  z-index: 3;
  @include respond-to("small") {
    padding: 0px;
    z-index: 9999;
  }
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
