.cf-approval-financial-dashboard {
  .filters {
    margin-top: 5vh;
    .filters-1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 3vw;
      row-gap: 2vw;
    }
    .filters-2 {
      margin-top: 2vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 3vw;
      row-gap: 2vw;
    }

    .styled-text-field.mutilSelect {
      background-color: transparent;
      .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
        background-color: #ffffff;
      }
    }
    .end-filter {
      display: flex;
      justify-content: end;
      gap: 20px;
      align-items: center;
    }
  }
}
