/** @format */
@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.subs-autocomplete {
  height: 30px;
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 30px;
  }

  .css-14puys2-MuiFormLabel-root-MuiInputLabel-root {
    top: -12px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined {
    top: -12px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 0px;
  }
  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    height: 30px;
    padding: 3px 0px 0px 3px !important;
    margin: -10px 0px;
  }
}

.subs-text-field {
  .css-14puys2-MuiFormLabel-root-MuiInputLabel-root {
    top: -12px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -12px;
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 5px 10px;
    height: 20px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
    top: 0px;
  }
}

.subs-text-field.subs-text-date {
  margin-right: 10px;
}

.person-add-corporation {
  padding: 20px;
  .drawer-header {
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 10px 0px;
    display: inline-block;
  }
  .corporation-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .css-26l3qy-menu {
      z-index: 2 !important;
    }
  }
}
.profile-details-container {
  .corporation-drawer {
    display: flex;
  }

  .g-r-2-multi {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }

  .custom_select_multi__control {
    min-height: 30px !important;
  }

  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  gap: 2em;
  .left-s {
    display: grid;
    grid-gap: 1em;
  }
  .right-s {
    display: grid;
    grid-gap: 1em;
  }
}
.main-person-container {
  position: relative;
  .mainTabContainer {
    background: #ffffff;
    // border: 1px solid #e1e7ff;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;
    align-items: center;
    position: fixed;
    top: 40px;
    z-index: 5;
  }
  .tab-container {
    border: none;
  }
}

.person-details-card-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 400px;
  margin-bottom: 10px;

  .input-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.input-title-paragrah {
  margin: 0px 0px 5px 0px;
  font-size: small;
  font-weight: lighter;
}
.g-r-2 {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 30px;
}
.content-container {
  display: grid;
  grid-gap: 10px;
}
.row-div {
  display: flex;
}

.pd-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
}
#react-select-25-listbox {
  z-index: 1000000;
}
.person-communication-container {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  padding: 1em;
  .search-container {
    padding-top: 1.5em;
    display: flex;
    grid-gap: 3em;
    padding-bottom: 1em;
  }
  .table-con {
    max-height: 30em;
    .table-cell {
      padding: 0px;
    }
  }
}
.corporation-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
