.bd-lead-dashobard {
  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }

  .hover {
    cursor: pointer;
  }

  .back-arrow {
    cursor: pointer;
    margin-top: 10px;
  }
}
