/** @format */

@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.basic-fixed-container {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .w-100 {
    width: 100%;
  }
  .search-container {
    display: flex;
    padding: 10px;
    gap: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .form-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
}
.store-details-container-att {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .w-100 {
    width: 100%;
  }
  .search-container {
    display: flex;
    padding: 10px;
    gap: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .form-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
}
.store-details-container-att {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .date-range-picker {
    display: grid;
    grid-template-columns: 40px 200px 20px 200px 100px;
    gap: 20px;
    align-items: center;
  }
  .date-range-picker-total {
    display: grid;
    gap: 20px;
    align-items: center;
    grid-template-columns: 164px 100px;
    justify-content: flex-end;
    padding-right: 260px;
  }
}

.store-details-container-att {
  .create-new-button-container {
    align-items: center;
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
    // padding: 10px 0px;
    display: inline-block;
  }
  .address-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .v-divider {
    width: 1px;
    height: 30px;
    border-right: 1px solid black;
  }
  .f-r-2 {
    display: grid;
    grid-template-columns: 1fr 60px;
    height: 25px;
  }
  .g-r-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    height: 25px;
  }
}
.delivery-container {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  display: flex;
  flex-direction: column;
}

.basic-fixed-container-attribute-page-2 {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .w-100 {
    width: 100%;
  }
  .search-container {
    display: flex;
    padding: 10px;
    gap: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 14px;
  }
  .form-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
  }
}
.dropdown-title {
  margin: 0px;
  font-size: 12px;
}
.m-b {
  margin-bottom: 8px;
}
