.create-grn-container {
  .grn-create-header {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    justify-content: space-between;
    .back-title {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .left-container {
      display: flex;
      align-items: center;
    }
    .right-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  .grn-info-section {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      span:first-child {
        color: gray;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .form-container {
    background-color: white;
    padding: 10px 50px;
    padding-bottom: 20px;   
  }

  .grn-invoice-details-container {
    display: grid;
    grid-template-columns: 220px 220px 220px 220px 220px;
    background-color: white;
    gap: 20px;
    padding: 0 42px;
  }
  .grn-attachment-form-container {
    display: flex;
    gap: 20px;
  }
  .grn-uploader-attachments {
    display: flex;
  }

  .grn-file-upload-container {
    display: flex;
    gap: 20px;
    background-color: white;
    padding: 10px;
    padding-left: 40px;
    align-items: center;
    justify-content: center;
    .invoice-additional-doc {
      max-width: 100%;
    }
    .attached-doc-list {
      display: flex;
      gap: 10px;
      padding: 10px;
    }
  }
}
