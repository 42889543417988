@import "../../../common-utilities/styles/variables.scss";
@import "../../../common-utilities/styles/responsive.scss";
.side-menu-v2-items {
  display: flex;
  flex-direction: column;
  .menu-item {
    display: flex;
    grid-gap: 20px;
    cursor: pointer;
    align-items: center;
    height: 43px;
    padding: 0px 20px;
    .menu-name {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      background-color: transparent;
      :hover {
        background-color: transparent;
      }
    }
    .side-bar-menu-label {
      font-size: 16px;
      line-height: 24px;
      @include respond-to(small) {
        font-size: 15px;
      }
    }
  }
  .menu-item-active {
    // height: 33px;
    background: #f8f8f8;
    border-radius: 8px;
    color: $primary;

    .side-bar-menu-label {
      color: #1d49ae;
      font-weight: bold;
      font-size: 16px;
      @include respond-to(small) {
        font-size: 15px;
      }
    }
  }
}
