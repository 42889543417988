@import '../../../../styles/responsive.scss';

.invoice-details-container {
  padding: 20px;
  max-width: 1140px;
  background-color: #f9f9f9;
  margin: auto;
  @include respond-to('small') {
    padding: 10px;
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    justify-content: space-between;
    background: white;
    margin-bottom: 10px;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .right-section {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      padding-right: 10px;
      @include respond-to('small') {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
  .invoice-details {
    .form-row,
    .address-bank-details-form-row {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
      .i-key-value {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include respond-to('small') {
          width: 45%;
        }
        .i-key {
          color: gray;
        }
        .i-value {
          color: #000;
        }
      }
      @include respond-to('small') {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .address-bank-details-form-row {
      display: grid;
      grid-template-columns: 250px 250px 1fr;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
      .approval-status {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .approval-key-value {
          display: grid;
          grid-template-columns: 200px 200px;
          align-items: center;
        }
        .i-key {
          padding-right: 10px;
        }
        .comment-list {
          .comment {
            display: grid;
            grid-template-columns: 100px 1fr;            
          }
        }
      }
    }
    .reject-form-row {
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: center;
      @include respond-to('small') {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .form-comments-row {
      display: grid;
      grid-template-columns: 350px 350px 350px;
      gap: 20px;
      margin-bottom: 20px;
      @include respond-to('small') {
        display: flex;
        flex-wrap: wrap;
      }
      .i-key-value {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .i-key {
          color: gray;
        }
        .i-value {
          color: #000;
        }
      }
    }
  }
  .approve-invoice-modal {
    .footer-buttons {
      display: flex;
      gap: 20px;
    }
  }
}
