.trasfer-con {
  margin-top: 1rem;
  .auto-complete-input {
    width: 100%;
  }
  .g-r-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    height: 55px;
  }
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .Mui-disabled {
    -webkit-text-fill-color: #333333ad !important;
    font-weight: 600;
  }
  background-color: white;
  padding: 2em 1em 2px 1em;
  .header {
    padding-bottom: 0px;
    font-weight: 700;
  }
  .sel-item {
    width: 90%;
  }
  .mr {
    margin-right: 30px;
  }
  .pb10 {
    padding-bottom: 10px;
  }
  .bold {
    font-weight: 700;
  }
  .input-title {
    margin: 0px;
    font-size: small;
    font-weight: lighter;
  }
  .shift-date {
    margin-left: 1em;
  }
  .add-gap {
    display: grid;
  }

  .table-con {
    margin-bottom: 2em;
    max-height: 13em;
    min-height: 10em;
    width: 100%;
    overflow: auto;
  }
  .transferTranTable {
    margin-bottom: 2em;
    max-height: 25em;
    min-height: 20em;
    width: 100%;
    overflow: auto;
  }
}
