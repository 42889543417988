.store-master-con {
  padding: 2rem;
  .body-table-cell {
    min-width: 10rem !important;
    word-break: break-all;
    padding: 0px !important;
  }
  .table-con-cell {
    z-index: 0 !important;
    // padding: 0px !important;
  }
}

.store-create-edit-modal {
  .input-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 20px;
    .mandatory-fields {
      span {
        color: red;
      }
    }
  }
  .save-button {
    display: flex;
    justify-content: end;
    margin-bottom: 30px;
  }
  .store-create-edit-modal-container {
    min-width: 60vw;
  }
}
