.button-color {
  background-color: #1756f3;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}
.deleteIcon {
  color: red;
}
.outlined-btn {
  color: #1756f3 !important;
  border-color: #1756f3 !important;
}
.del-outline-btn {
  color: white !important;
  background-color: red !important;
}
