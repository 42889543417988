.edit-po-container {
  .po-edit-header {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    justify-content: space-between;
    .back-title {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .info-section {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      .info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        span:first-child {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .left-container {
      display: flex;
      align-items: center;
    }
    .right-container {
      display: flex;
      align-items: center;
      gap: 20px;
      .info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        span:first-child {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .form-container {
    background-color: white;
    padding: 0px 10px;
    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 20px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .po-attachment-form-container {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background-color: white;
    .add-additional-doc{
      max-width: 100%;
    }
  }
  .po-additional-attachment-container {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background-color: white;
    .add-additional-doc {
      max-width: 100%;
    }
  }
}

.po-add-items-container {
  background-color: white;
  padding: 10px;
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .left-container {
      display: flex;
      align-items: center;
    }
    .right-container {
      display: flex;
      align-items: center;
      gap: 20px;
      .info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        span:first-child {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .po-add-items-row {
    display: grid;
    grid-template-columns: 1fr 1fr 0.8fr 0.9fr 0.8fr 0.8fr 0.6fr 0.6fr 0.8fr 0.6fr 0.8fr 1fr 1fr 0.2fr;
    gap: 10px;
    margin-bottom: 10px;
  }
  .po-accordion-summary {
    .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .left-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .right-container {
        display: grid;
        grid-template-columns: 250px 250px;
        align-items: center;
        gap: 10px;
        .error {
          color: red;
        }
      }
    }
  }
}
