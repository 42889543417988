@import "../../../../styles/responsive.scss";

.invoice-details-container {
  padding: 20px;
  max-width: 1140px;
  background-color: #f9f9f9;
  margin: auto;
  @include respond-to("small") {
    padding: 10px;
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    justify-content: space-between;
    background: white;
    margin-bottom: 10px;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .right-section {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @include respond-to("small") {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
  .invoice-details {
    .form-row {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
      .i-key-value {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include respond-to("small") {
          width: 45%;
        }
        .i-key {
          color: gray;
        }
        .i-value {
          color: #000;
        }
        .first-uppercase::first-letter {
          text-transform: uppercase;
        }
      }
      @include respond-to("small") {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .attachments {
      grid-template-columns: 300px 300px 300px;
      justify-content: center;
      @include respond-to("small") {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .form-comments-row {
      display: grid;
      grid-template-columns: 350px 350px 350px;
      gap: 20px;
      margin-bottom: 20px;
      @include respond-to("small") {
        display: flex;
        flex-wrap: wrap;
      }
      .i-key-value {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .i-key {
          color: gray;
        }
        .i-value {
          color: #000;
        }
      }
    }
  }
  .approve-invoice-modal {
    .footer-buttons {
      display: flex;
      justify-content: end;
      // gap: 40px;
    }
    .footer-approver-buttons {
      margin-top: 15px;
    }
  }
  .custom-modal-container {
    .invoice-tracking-document-modal {
      .modal-body {
        padding: 10px;
      }
    }
  }
}
