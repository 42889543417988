.imprest-container {
  margin-top: 15px;
  .imprest-btn-con {
    display: flex;
    justify-content: flex-end;
  }
  .details-row {
    display: flex;
    justify-content: space-between;
  }
}
