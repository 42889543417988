.button-container-start {
  display: flex;
  margin-bottom: 2em;
  margin-top: 3em;
  width: 100%;
}
.address-content-container {
  display: grid;
  grid-gap: 20px;
}
.main-add-con {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  padding: 1em;
  .g-r-3 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
}
.add-type-div {
  margin-bottom: 2em;
}
.memo-category-con {
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  padding: 1em;
}
