@import '../../../../styles/responsive.scss';

.budget-list {
  padding: 20px 0px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
  }
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .budget-ui-btns {
    display: flex;
    gap: 20px;
  }
}
