@import '../../../../styles/responsive.scss';

.vendor-onboard-form-container {
  padding: 20px;
  max-width: 1140px;
  background-color: #f9f9f9;
  margin: auto;
  @include respond-to('small') {
    padding: 10px;
  }
  .submitted-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .comment-list {
      .comment {
        display: grid;
        grid-template-columns: 100px 1fr;        
      }
    }
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    justify-content: space-between;
    background: white;
    margin-bottom: 10px;
    .title {
      font-weight: bold;
      font-size: 20px;
      padding-left: 20px;
    }
    .right-section {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @include respond-to('small') {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
  .vendor-onboard-form-details {
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
    @include respond-to('small') {
      overflow-y: scroll;
      max-height: calc(100vh - 80px);
    }
    .sub-heading {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .footer-buttons {
      display: flex;
      justify-content: flex-end;
    }
    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
      @include respond-to('small') {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
      .form-autocomplete {
        @include respond-to('small') {
          width: 100%;
        }
      }
      &.msme-status{
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
