.franchise-memo-con {
  padding-right: 2rem;
  padding-left: 2rem;
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .styled-text-field {
    background-color: #FFFFFF;
  }
}
.memo-timeline-details {
  display: flex;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.068);
  border-radius: 5px;
  margin-bottom: 1rem;
  max-height: 7rem;
  overflow: auto;
  .p-header {
    width: max-content;
    max-width: fit-content;
  }
  .content {
    background-color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 2px;
    margin-top: 2px;
  }
}
