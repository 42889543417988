@import "../../packages/common-utilities/styles/variables.scss";
.ck-editor__editable {
  min-height: 375px;
}
.bullentin-autocomplete {
  width: 100%;
}
.quill-styles {
  height: 400px;
  margin-bottom: 2rem;
}
.btn-quill-con {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.si-row {
  grid-template-columns: 1fr 1fr 1fr;
  word-break: break-all;
}
.ck.ck-editor__editable_inline {
  border: 1px solid #e0e0e0;
}
.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0;
}

.blogs {
  margin-top: 1rem;
  .header {
    h3 {
      text-align: center;
      margin-left: 10rem;
    }
  }
  .f25 {
    font-size: 25px;
  }
  .ml {
    margin-left: auto;
  }
  .mb {
    margin-bottom: 10px;
  }
  .blogsList {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blog-con {
      border: 1px solid #e3e5e6;
      width: 70%;
      background-color: white;
      border-radius: 0.5rem;
      margin-bottom: 2rem;
      cursor: pointer;
      padding: 1.5rem 1rem 1.5rem 1rem;
      display: flex;
      flex-direction: column;
      transition: all 0.4s ease-in-out;
    }
    .blog-con:hover {
      background-color: $gray-bg;
      transform: scale(1.03);
    }
    .edit-btn {
      margin: 0.2rem 3rem 1rem 3rem;
      display: flex;
      justify-content: flex-end;
    }
    .blog-right-con {
      display: flex;
      flex-direction: column;
      align-items: center;
      .creation-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          margin-right: 1rem;
        }

        .date {
          margin-top: 0px;
          color: gray;
        }
        .name {
          font-size: 15px;
          font-weight: bolder;
        }
      }
    }
  }
  .body {
    padding: 10px;
    margin: 0.5rem 3rem 0rem 3rem;
    border-radius: 10px;
  }
  .form {
    padding: 0px 20px 0px 20px;
    .common-rnd-btn-10 {
      border-radius: 10px;
    }
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .common-rnd-btn-10 {
    border-radius: 10px;
  }
  .addButton {
    height: 35px;
    margin-top: 25px;
    margin-left: 25px;
  }
  .ckEditor {
    padding-top: 0px;
    padding-right: 10px;
    max-height: 60vh;
    overflow: auto;
  }
  .mt10 {
    margin-top: 2rem;
  }

  .g1 {
    margin: 0px -5px 0px -10px;
  }
  .pt1 {
    padding-top: "0px";
  }
  .catName {
    height: 40px;
    background-color: white;
    min-width: 400px;
  }
}
.blog-info-con {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.blog-title {
  font-size: 20px;
  font-weight: bolder;
  color: black;
}
.box {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #c8e6c9;
  border: none;
  box-shadow: 24px;
  border-radius: 20px;
}
.ck.ck-editor__editable_inline {
  border: 1px solid #bdbbbf !important;
}
.bulletin-creation {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.blog-attachment {
  margin-left: 3rem;
}
.email-error {
  color: red;
}
