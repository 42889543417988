/** @format */
@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  .logo {
    width: 180px;
    padding-left: 10px;
    cursor: pointer;
    @include respond-to("small") {
      width: 100px;
      padding-left: 2px;
    }
  }
  .menu {
    display: flex;
  }
}
.header-container.non-changed {
  align-items: center;
  background: #fff;
  border: 1px solid #e1e7ff;
  box-sizing: border-box;
  display: flex;
  height: 75px;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.logo-con {
  display: flex;
  align-items: center;
}
