@import "../../../common-utilities/styles/variables.scss";
.si-common-table-container {
  overflow-y: auto;
  width: -webkit-fill-available;
  .si-table {
    width: 100%;
  }
  .si-header {
    height: 3rem;
    background: $primary;
    border: 1px solid #e1e7ff;
    color: white;
  }
  .si-content-row {
    border: 1px solid #e1e7ff;
    width: 100%;
    .si-cell {
      padding: 2px 10px;
      text-align: center;
    }
  }
  .si-pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
  .clickable {
    cursor: pointer;
    color: $primary;
  }
}
.html-table-container {
  overflow-y: auto;
}
