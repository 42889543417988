.react-table-container {
  max-width: 100%;
  overflow: scroll;
  table {
    border-collapse: collapse;
    width: -webkit-fill-available;
    thead {
      & tr:nth-child(1) {
        color: white;
        background-color: black;
      }
      & tr:nth-child(2) {
        color: white;
        background-color: #915133;
      }
      & tr:nth-child(3) {
        color: white;
        background-color: #008a52;
      }
      th {
        border: 1px solid #dddddd;
        text-align: center;
        padding: 5px;
      }
    }
    tbody {
      td {
        border: 1px solid #dddddd;
        text-align: center;
        padding: 5px;
      }
    }
  }
}
.si-pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
