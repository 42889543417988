.item-list {
  padding: 20px 0px;
  .itl-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .left-container {
      display: flex;
      gap: 20px;
    }
  }
}

.create-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.price-history {
  .table-header,
  .table-rows {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .table-rows {
    padding: 10px 0;
  }
}
