@import "../../styles/variables.scss";
.allowed-apps {
  padding: 0px 20px 20px 30px;
  .apps-con {
    display: flex;
    justify-content: center;
    height: fit-content;
    gap: 30px;
    flex-wrap: wrap;
  }
  .bulletins-con {
    border-left: 1px solid #c2bfbfcd;
    padding-left: 1rem;
  }
  .header {
    text-transform: uppercase;
    font-size: 20px;
    color: black;
    text-align: center;
    letter-spacing: 1px;
  }
}
.title-header {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: $secondary;
  margin-top: 10px;
}

.no-broadcast {
  display: flex;
  justify-content: center;
  // color: #1756f3;
  font-size: 20px;
}
