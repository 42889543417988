.tag-con {
  .button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .search-field {
      display: flex;
      align-items: center;
      min-width: 20%;
      .main-search-icon {
        cursor: pointer;
      }
    }
    @media (max-width: 1300px) {
      .search-field {
        min-width: 30%;
      }
    }
    @media (max-width: 1100px) {
      .search-field {
        min-width: 35%;
      }
    }
    .button {
      background-color: "#0063cc";
    }
  }
}
