.pdf-viewer-container {
  /* For pdf */
  display: inline-block;
  .pdf-document-header {
    background-color: grey;
    width: 694px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .pdf-preview {
    display: grid;
    gap: 20px;
    grid-template-columns: 128px 694px;
    .pdf-overview {
      .react-pdf__Page__canvas {
        height: auto !important;
        width: 100px !important;
      }

      .react-pdf__Page__annotations {
        padding: 0px;
        height: auto !important;
        width: 100px !important;
      }
    }
    .active-page {
      border: 2px solid green;
      padding: 4px;
      margin: 5px;
    }
  }
  .pdf-no-preview {
    display: grid;
    grid-template-columns: 694px;
  }
  .pdf-document {
    height: 100%;
    max-height: 725px;
    overflow: auto;
    padding: 20px;
    .react-pdf__Document {
      max-height: 500px;
      overflow-y: scroll;
    }
  }
  .react-pdf__Page__canvas {
    border: 1px solid grey;
    box-shadow: 5px 5px 5px 1px rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    height: auto !important;
    width: 100px !important;
  }

  .react-pdf__Page__annotations {
    padding: 0px;
    height: auto !important;
    width: 100px !important;
  }

  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 630px !important;
  }
  &.pdf-full-view {
    position: fixed;
    left: 0px;
    top: 0px;
    background: white;
    width: 100%;
    height: 100vh;
    .pdf-document-header {
      width: 100%;
    }
  }
  .page-number-container {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
  }
}
