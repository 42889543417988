@import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
.main-thankyou-container {
    .thankyou {
        padding: 20px;
        border: 1px solid;
        min-width: 33vw;
        border-radius: 10%;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .BellcorpStudioLogo.main-thankyou-container{
        position: absolute;
        left: 80%;
        top: 40%;
    }
    .approval-container {
        display: flex;
        justify-content: center;
        .approval-icon {
            transform: scale(4.3);
            margin-top: 25px;
            margin-bottom: 50px;
        }
    }
}

