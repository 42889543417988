.new-textfield {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background: #ffffff;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #565656;
}

.user-con {
  padding: 1em 3em 0.5em 3em;

  .body-table-cell {
    min-width: 10rem !important;
    word-break: break-all;
    padding: 0px !important;
  }

  .btn-con {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
  }

  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    word-break: break-all;
  }
  .end-btns {
    display: flex;
    justify-content: end;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
  .rounded-btn {
    border-radius: 15px;
  }
}

.create-user-con {
  padding: 2em;

  .cancel-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-title {
    margin-bottom: 2px;
    font-size: small;
  }

  .userid-container {
    position: relative;

    .userId-warning {
      position: absolute;
      bottom: -15px;
      left: 0;
      font-size: xx-small;
      color: red;
    }
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 20px;
    .user-modal-button {
      margin-top: 50px;
      border-radius: 10px;
    }
  }
  .select-apps.styled-select {
    .MuiFormControl-root {
      .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
        top: -15px;
      }
    }
  }
}
