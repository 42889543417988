.filter-menu-container {
  box-sizing: border-box;
  width: 180px;
  height: 227px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  .menu {
    width: 154px;
    height: 33px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-family: "DMSans-Bold";
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    padding-left: 5px;
  }
  .active-menu {
    width: 153px;
    height: 33px;
    background: #f8f8f8;
    border-radius: 4px;
    color: #1d49ae;
  }
}
