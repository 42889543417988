@import "../../../common-utilities/styles/responsive.scss";
.cash-certificate {
  margin-top: 2rem;
  margin-bottom: 1rem;
  .denomination {
    h5 {
      margin-bottom: 5px;
    }
    table {
      tbody {
        td {
          padding-right: 30px;
          padding-left: 30px;
          padding-top: 1px;
          padding-bottom: 1px;
          @include respond-to("small") {
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 0.5px;
            padding-bottom: 0.5px;
          }
        }
      }
    }
    .total-amount {
      display: flex;
      margin-top: 5px;
      width: 85%;
      margin: auto;
      align-items: center;
      justify-content: space-between;
      @include respond-to("small") {
        width: 50%;
      }
    }
  }
  .store-details {
    margin-bottom: 1rem;
    .details-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
    }
  }
  .certified-date {
    display: flex;
  }
  .balance {
    .balance-con {
      border: 1px solid rgba(128, 128, 128, 0.583);
      border-radius: 5px;
      margin-bottom: 13px;
      padding: 10px;
    }
    .item-div {
      .div-label {
        color: gray;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(128, 128, 128, 0.315);
    }
    .remove-border {
      border: none;
    }
  }
}
.view-certificate {
  .header-certificate-con {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .back-con {
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
      }
    }
  }
  .details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
}
.warning-con {
  background-color: rgba(255, 0, 0, 0.195);
  width: 60%;
  margin: auto;
  padding: 1rem;
  border-left: 5px solid red;
  .warn-title-con {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .title {
      margin-left: 1rem;
    }
  }
  p {
    color: red;
    font-size: 18px;
    margin-left: 2.2rem;
  }
}
