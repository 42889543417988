.cancel-con {
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-size: large;
    margin-left: 1rem;
  }
}

.central-reports {
  h3 {
    text-align: center;
    margin-bottom: 3rem;
  }
}
.central-reports-con {
  padding-right: 2rem;
  padding-left: 2rem;
}
.no-reports-header {
  display: flex;
  justify-content: center;
  color: grey;
}
