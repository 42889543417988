.downloadgrid {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  align-items: center;
  column-gap: 2vw;
}

.approver-details-modal {
  .approver-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .approver-content {
    word-break: break-all;
  }
}

.modal-body {
  .flexEnd {
    display: flex;
    justify-content: end;
  }
  .approverInput {
    width: 100% !important;
  }
}
