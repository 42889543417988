
.vm-list {
  padding: 20px 0px;
  .vm-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .left-container{
      display: flex;
      gap: 20px;
    }
  }
}

.create-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
