.reports-contain {
  margin-top: 2rem;
  .report-filter-con {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
  }
  .table-div {
    width: 98%;
    margin: 1em auto 0px auto;
  }
  .downloadReportsbutton {
    display: flex;
    justify-content: end;
    width: 98%;
    margin: 0em 20px 0px auto;
  }
}
