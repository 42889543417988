.admin-app-access-con {
  display: flex;
  justify-content: center;
  height: fit-content;
}
.access-header {
  text-align: center;
  margin-bottom: 1rem;
}

.admin-allowed-apps {
  padding: 20px;
  .apps-con {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .my-access-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #1756f3;
  }
  .user-intro {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}
