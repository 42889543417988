@import "../../packages/common-utilities/styles/responsive.scss";
@import "../../packages/common-utilities/styles/variables.scss";
.user-account-con {
  padding: 2rem 5rem;
  @include respond-to("small") {
    padding: 0px;
  }
  .go-back {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      font-size: large;
      margin-left: 1rem;
    }
  }
  .profile-con {
    margin: auto;
    height: 65vh;
    width: 90%;
    overflow: auto;
    border-radius: 0.5rem;
    background-color: white;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 4px solid $primary;
    border-bottom: 4px solid $primary;
    @include respond-to("small") {
      height: auto;
      width: 100%;
      box-shadow: none;
    }

    .title {
      word-break: break-all;
    }
    .profile-picture {
      width: 100px;
      height: 100px;
      margin-bottom: 2rem;
    }
  }
}
