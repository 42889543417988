@import "../../../../styles/responsive.scss";

.vendor-list{
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  padding: 20px;
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;    
  }    
}
