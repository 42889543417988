.create-update-document {
  .modal-field {
    margin-bottom: 20px;
  }
  .auto-completes {
    display: grid;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .end-con {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .end-btns {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
}

.create-update-category {
  .modal-field {
    margin-bottom: 20px;
  }
  .auto-completes {
    display: grid;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .end-btns {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
}