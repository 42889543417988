.common-btn-ctr {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
  .common-modal-crt-btn {
    border-radius: 10px;
  }
}
.common-rnd-btn {
  border-radius: 20px;
}
.common-rnd-btn-10 {
  border-radius: 10px;
}
.edit-app-modal {
  height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.all-apps {
  .btn-con {
    margin-top: 1rem;
  }
  .apps-con {
    display: flex;
    justify-content: center;
    height: fit-content;
    gap: 30px;
    flex-wrap: wrap;
  }
}
