.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.subway-finance-bulk-upload {
  border: 1px solid rgba(0, 138, 82, 0.5);
  font-family: DMSans-Regular;
  font-weight: 500;
  font-size: 0.875rem;
}
.company-list {
  padding: 20px 0px;
  .com-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .left-container {
      display: flex;
      gap: 20px;
    }  
  }
}

.create-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
