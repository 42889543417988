@import "../../packages/common-utilities/styles/variables.scss";
.details-con {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  .app-card {
    bottom: 0%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    height: 150px;
    width: 300px;
    border-radius: 14px;
    transition: all ease-out 0.05s;
    .con-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .card-app-image {
        width: 60px;
        height: 60px;
      }
      .app-logo {
        width: 84px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #3f78fd32;
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: #464255;
        width: 100px;
        margin-left: 1.5rem;
      }
    }
  }
  .edit-card {
    position: relative;
    transition: transform 0.9s;
  }
  .edit-icon {
    position: absolute;
    background-color: rgba(59, 57, 57, 0.162);
    border-bottom-left-radius: 50%;
    padding-bottom: 5px;
    top: 1px;
    right: 1px;
    display: none;
  }
  .edit-card:hover {
    border-top: 4px solid $primary;
    border-bottom: 4px solid $primary;
    box-shadow: none !important;
    border-right: none;
    border-left: none;
  }
  .edit-card:hover .edit-icon {
    display: block;
  }

  .app-card:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    -moz-box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
    border-right: none;
    border-left: none;
  }
  .app-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      margin-bottom: 0px;
      word-wrap: break-word;
      margin-top: 5px;
      font-size: 15px;
      text-align: center;
    }
    .role {
      margin: 1px;
    }
  }
}
.details-con.download-con {
  cursor: auto;
}
