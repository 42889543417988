/** @format */

@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.MuiTabs-root {
  min-height: 40px !important;
  .MuiButtonBase-root {
    min-height: 40px;
    padding: 5px 12px;
  }
}

.store {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  .tab {
    text-transform: none;
  }
  .buttonDiv {
    position: absolute;
    left: 85%;
    top: 62px;
    .storeButton {
      text-transform: none;
      padding: 5px;
      margin: 3px 5px 0px 5px;
      height: 25px;
    }
  }
}

.storeDetails {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  .header {
    height: 0px;
    position: relative;
    top: -9px;
    left: 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .textField {
    .MuiInputBase-input {
      padding: 0px;
    }
    height: 23px;
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
    width: 80%;
  }
  .select {
    .MuiInputBase-input {
      padding: 0px;
    }
    height: 23px;
    font-size: 14px;
    margin-left: 5px;
    padding: 5px;
    width: 80%;
  }
  .icon {
    position: relative;
    left: 5px;
    top: 10px;
    height: 20px;
    color: green;
  }
  .grayIcon {
    height: 20px;
    color: gray;
  }
  .greenIcon {
    height: 20px;
    color: green;
  }
  .label {
    padding: 3px 5px 2px 5px;
  }
  .content {
    padding: 3px 5px 2px 5px;
  }
  .check {
    height: 25px;
    padding-top: 5px;
  }
  .date {
    .MuiInputBase-root {
      padding: 2px 10px 0px 5px;
      margin-top: -5px;
    }
  }
}

.store-memos-container {
  .top-section {
    margin-bottom: 10px;
  }
  .search-container {
    display: flex;
    padding: 10px;
    gap: 20px;
  }
  .memo-list-container {
    .table-cell {
      color: white;
    }
  }
}

.store-details-container {
  .title {
    font-weight: bold;
    font-size: 15px;
    padding: 5px 0px;
    display: inline-block;
  }
  .address-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .css-26l3qy-menu {
      z-index: 2 !important;
    }
  }
  .v-divider {
    width: 1px;
    height: 30px;
    border-right: 1px solid black;
  }
  .f-r-2 {
    display: grid;
    grid-template-columns: 1fr 60px;
    height: 20px;
  }
  .g-r-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    height: 20px;
  }
}

.store-add-address {
  padding: 20px;
  .drawer-header {
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 10px 0px;
    display: inline-block;
  }
  .address-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .css-26l3qy-menu {
      z-index: 2 !important;
    }
  }
}
.memo-search-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.move-to-top {
  align-items: flex-start !important;
}
.memo-timeline {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: inherit;
  max-height: -webkit-fill-available;
  .comments-section {
    margin-top: 2rem;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.068);
    padding: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .timeline-con {
    overflow: auto;
    width: 49rem;
    .content-reason {
      text-align: center;
      color: rgb(118, 41, 41);
    }
    .timeline-content {
      border: 1px solid grey;
      padding: 1rem;
      text-align: center;
      border-radius: 5px;
    }
  }
}
