@import '../../../../styles/responsive.scss';

.invoice-form-container {
  padding: 20px;
  max-width: 1140px;
  background-color: #f9f9f9;
  margin: auto;
  @include respond-to('small') {
   padding: 10px;
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    background: white;
    margin-bottom: 10px;
  }
  .invoice-card {
    overflow-y: scroll;
  }
  .invoice-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include respond-to('small') {
      display: flex;
      flex-wrap: wrap;
    }
    .form-row {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      gap: 20px;
      align-items: flex-start;
      .MuiFormControl-root {
        margin: 0px;
        @include respond-to('small') {
          width: 100%;
        }
      }
      .form-autocomplete {
        @include respond-to('small') {
          width: 100%;
        }
      }
      .is-remarks{
        width: 518px;
        @include respond-to('small') {
          width: 100%;
        }
      }
      @include respond-to('small') {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .attachments {
      grid-template-columns: 300px 300px 300px;
      justify-content: center;
      .spidle-upload-file {
        @include respond-to('small') {
          width: 100%;
          max-width: initial;
        }
      }
    }
    .footer-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
  .custom-modal-container {
    .invoice-tracking-document-modal {      
      .modal-body {
        padding: 10px;
      }
    }
  }
}
