.approval-container {
  margin: auto;
  padding: 20px;
  overflow-y: auto;
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
  }
  .clickable {
    cursor: pointer;
    color: #1756f3;
  }

  .nestedModal .si-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .custom-modal-container .modal-content.tableModal {
    width: 80%;
    max-width: 80%;
  }
  .approval-buttons {
    display: flex;
    gap: 20px;
  }
}

.approval-container.unapproved .si-row {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.approval-container .nestedModal .si-row {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1vw;
}

.approval-container .nestedModal .si-row span {
  width: 22vw;
  word-break: break-all;
}

.approval-container .nestedModal .si-row a {
  width: 22vw;
  word-break: break-all;
}

.badgeMargin {
  margin-top: 0.4rem;
}
.custom-modal-container .modal-content.approvalModal {
  margin-top: 10%;
}

.custom-common-modal-container .modal-content.approvalModal {
  // left: 0 !important;
  // top: 43% !important;
  margin-left: 50px;
}
