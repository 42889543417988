@import "../../../common-utilities/styles/responsive.scss";
.back-container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  // padding: 20px;
  justify-content: space-between;
  @include respond-to("small") {
    padding: 0px;
    // flex-direction: column;
  }
  .r-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
}
.basic-info {
  .info-row {
    color: #008a52;
    padding: 10px 20px;
  }
  .value {
    color: black;
    padding: 5px;
  }
}
.filters-row {
  display: flex;
  grid-gap: 20px;
  // padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-end;
  @include respond-to("small") {
    flex-wrap: wrap;
  }
  .doc-type {
    min-width: 220px;
  }
}
