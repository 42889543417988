@import "../../../common-utilities/styles/variables.scss";
.table-cell {
  background-color: $gray-bg !important;
  color: #a6a9ad !important;
  font-size: 16px !important;
  padding: 7px !important;
  line-height: normal !important;
  border: none !important;
}
.mui-table-row {
  box-sizing: border-box;
  height: 60px;
  background: #ffffff;
}
.body-table-cell {
  border-bottom: 0px;
  font-weight: 400;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.con-scroll::-webkit-scrollbar-thumb {
  background-color: #c7c9cb; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}

.con-scroll::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  @media only screen and (min-width: 2000) {
    display: none;
  }
}

.con-scroll::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  @media only screen and (min-width: 2000) {
    display: none;
  }
}
.sticky-table-container {
  max-height: 23em;
  min-height: 5em;
  width: 100%;
  overflow: auto;
}
.read-more-text {
  margin: 0px;
  .show-more {
    cursor: pointer;
    text-decoration: underline;
    color: #29528c;
  }
}
