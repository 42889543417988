@import "../../packages/common-utilities/styles/responsive.scss";
.roles-con {
  padding: 0.5em 3em 0.5em 3em;
  @include respond-to("small") {
    padding: 1em;
  }
  .si-table-con {
    width: 80%;
    margin: auto;
  }
  .btn-con {
    margin-top: 1rem;
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
    .common-rnd-btn {
      border-radius: 10px;
    }
  }
  .si-row {
    grid-template-columns: 0.5fr 1fr;
    word-break: break-all;
  }
}
.create-role-con {
  padding: 2em;
  .cancel-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
