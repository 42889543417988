@import "../../styles/variables.scss";
.all-bulletin-con {
  height: 200px;
  background-color: white;
  .blog-card {
    height: 100%;
    padding: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: $primary;
    }

    .body {
      margin-top: 0px;
      color: rgb(149, 146, 146);
    }

    .blog-details {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .body {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: black;
      }
    }

    .category-con {
      display: flex;
      margin-top: 5px;

      .category {
        background-color: #dedede;
        padding: 0.5px 3px;
        display: flex;
        margin-top: 5px;
      }
    }

    .creation-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 5px;
    }

    .read-more-con {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 5px;
      .read-more {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: center;
        color: black;
        cursor: pointer;
        border-bottom: 1px solid black;
      }
    }
  }
}

.bullentin-filter-con {
  background-color: #dadcdd31;
  padding-top: 1rem;
  padding-bottom: 1px;
}
.all-bulletin-con::-webkit-scrollbar {
  width: 0em;
}
.moving-headline {
  animation: move-headline 20s linear infinite;
}

@keyframes move-headline {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes move-headline-back {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

.blog {
  padding-right: 1rem;
  padding-left: 1rem;
  width: 80vw;
  table {
    border-collapse: collapse;
    td,
    th {
      border: 1px solid #140b0b;
      padding: 8px;
    }
  }
  .attachment {
    margin-left: 1.5rem;
    margin-top: 2rem;
  }
}
