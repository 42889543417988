.main-doc-con {
  max-width: 1440px;
  margin: auto;
  padding: 0 60px;
  .create-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 2em;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
  }
  .btn-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    word-break: break-all;
    min-height: 43px;
    column-gap: 1rem;
  }
  .center-eye-icon {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
  .edit-icon {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
}
.company-doc {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 2em;
  .doc-data-div {
    width: 80%;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 50px;
      letter-spacing: 0.3px;
      color: #1d49ae;
    }
  }
}
.upload-doc-div {
  margin-top: 3em;
}
.btn-con {
  display: flex;
  margin: 0 auto;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
.form-header {
  justify-content: space-between;
}
