.subs-text-area {
  height: 48px !important;
}
.store-insurance-container {
  background-color: white;

  .tab-panel-container {
    padding: 10px;
  }

  .storeInsuranceDetails {
    .custom_select__control {
      min-height: 30px !important;
      height: 30px !important;
    }

    .custom_select__value-container {
      margin-top: -4px !important;
    }

    .custom_select__indicators {
      margin-top: -4px !important;
    }
    .gray {
      color: gray;
    }
    .red {
      color: red;
    }
    .commentText {
      margin-top: 10px;
      margin-left: 10px;
      width: 95%;
    }
    .insuranceDetailButton {
      background-color: lightgrey;
      color: white;
      transform: scale(0.9);
    }
    .rotate-roller {
      rotate: -118deg;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      // padding: 10px 0px;
      display: inline-block;
    }
    .address-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .v-divider {
      width: 1px;
      height: 30px;
      border-right: 1px solid black;
    }
    .f-r-2 {
      display: grid;
      grid-template-columns: 1fr 60px;
      height: 20px;
    }
    .g-r-2 {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      height: 20px;
    }
    .mb-1 {
      margin-bottom: 10px;
    }
    .w-80 {
      width: 80%;
    }
    .w-60 {
      width: 60%;
    }
  }
}
