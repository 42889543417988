@import "../../../../styles/responsive.scss";

.vendor-request-list {
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .download-con {
    display: flex;
  }
  .vendor-filters-con {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  padding: 10px;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .invoice-filter-container {
    display: grid;
    gap: 20px;
    align-items: center;
    grid-template-columns: 200px 200px 200px 200px 200px 100px 150px;
    .dropdown-list {
      @include respond-to("small") {
        width: 100%;
      }
    }
    @include respond-to("small") {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .action-btns {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
  .invoice-table {
    .si-row {
      grid-template-columns: 100px 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 100px 100px 100px 0.5fr 150px;
      word-break: break-all;
    }
    &.store {
      .si-row {
        grid-template-columns: 100px 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 100px 100px 0.6fr 140px;
        word-break: break-all;
      }
    }
  }
}
