.information-modal {
  .item-label-value {
    font-size: medium;
    display: grid;
    grid-template-columns: 1fr 1fr;
    word-break: break-all;
  }
  .btn-container {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
}
