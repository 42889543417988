/** @format */

@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.view-case-container {
  .close-case-container {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  // max-width: 1440px;
  min-height: 85vh;
  margin: auto;
  padding: 0 60px;
  .vc-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
    .info-label {
      padding: 5px 10px;
      background: #028a52;
      border-radius: 5px;
      color: white;
      font-size: 14px;
    }
    .l-c {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
    .r-c {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
  }
  .vc-body-container {
    display: grid;
    grid-template-columns: 1fr 300px;
    background-color: white;
    padding: 10px;
  }
  .edit-button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.view-ticket-container {
  width: 906px;
  height: 100vh;
  background: #1756f3;
  padding: 40px 24px;
  @include respond-to("small") {
    width: 100vw;
    padding: 0px;
  }

  .close-btn-title {
    display: flex;
    align-items: center;
    grid-gap: 44px;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
    @include respond-to("small") {
      font-size: 18px;
      justify-content: flex-start;
    }
  }
  .details-table {
    width: 758px;
    height: calc(100vh - 180px);
    background: #ffffff;
    border: 1px solid #e1e7ff;
    box-sizing: border-box;
    margin-left: 84px;
    margin-top: 33px;
    overflow: scroll;
    @include respond-to("small") {
      width: auto;
      height: auto;
      margin: 0px;
    }
    .main-input-container {
      margin: 20px;
      .input {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .free-services {
    width: auto;
    height: auto;
  }
  .service-row {
    display: grid;
    grid-template-columns: 100px 124px 100px;
    grid-gap: 20px;
    width: fit-content;
    background: white;
    padding: 4px 10px;
    font-size: 12px;
    align-items: center;
    border-bottom: 1px solid #ebe6e6;
  }
  .error {
    background: red;
    color: white;
  }
  .auto-height {
    height: auto !important;
    min-height: 54px;
  }
}

.case-summary-container {
  display: flex;
  gap: 20px;
  .display-flex {
    display: flex;
    justify-content: space-between;
  }
  .s-title {
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0px;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
  }
  .info-row {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
  .csc-r-s {
    border-left: 1px solid lightgray;
    padding-left: 10px;
  }
}

.comment-text-area {
  width: 100%;
}

.take-action-container {
  background-color: $gray-bg;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .take-action {
    background-color: $primary;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    color: white;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    .mi-input {
      background-color: white;
    }
    .subway-select__menu {
      z-index: 2;
    }
  }
  .take-action-attachments {
    border: 1px dotted;
    padding: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    min-height: 59px;
  }
}

.take-action-container-for-store {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .take-action {
    background-color: $primary;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    color: white;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    .mi-input {
      background-color: white;
    }
    .subway-select__menu {
      z-index: 2;
    }
  }
  .take-action-attachments {
    border: 1px dotted;
    padding: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    min-height: 59px;
  }
}

.case-attachments-container {
  .photo-card {
    text-align: center;
    padding: 10px;
    .name {
      a {
        cursor: pointer;
      }
    }
  }
  .name {
    padding-top: 5px;
    border-top: 1px solid gray;
  }
  .image {
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding-bottom: 5px;
  }
  .doc-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
}

.close-case-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  strong {
    font-size: 18px;
  }
  .btn-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.select-vendor-modal {
  .accept-reject-button-container {
    display: flex;
    gap: 20px;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
  }
}
