/** @format */
@import "../../../common-utilities/styles/responsive.scss";

.create-upload-tickets {
  // padding: 0em 2em 0em 2em;
  padding: 0em;
  display: flex;
  flex-direction: column;
  @include respond-to("small") {
    padding: 10px;
  }
  .back-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 20px;
    justify-content: space-between;
    @include respond-to("small") {
      padding: 0px;
      flex-direction: column;
    }
    .r-container {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }
  .create-upload-form {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    flex-wrap: wrap;
    justify-content: space-between;
    // padding-left: 40px;
    @include respond-to("small") {
      padding: 0px;
      margin-top: 2em;
      margin-bottom: 3em;
    }
    .form-row {
      // display: grid;
      grid-gap: 10px;
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      @include respond-to("small") {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
      }
      .label {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #333333;
      }
      .sub-form {
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
      }
      .service-row {
        display: grid;
        grid-template-columns: 124px 124px 124px 50px;
        background: white;
        padding: 4px 10px;
        font-size: 12px;
        align-items: center;
        border-bottom: 1px solid #ebe6e6;
      }
    }
  }
  .filters-row {
    display: flex;
    grid-gap: 20px;
    padding: 10px;
    justify-content: flex-end;
    @include respond-to("small") {
      flex-direction: column;
    }
    .doc-type {
      min-width: 220px;
    }
  }
}

.doc-preview {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  flex-wrap: wrap;
  .doc-list {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
  }
  .doc-conatiner {
    display: flex;
    flex-direction: column;
    width: 150px;
    border-radius: 10px;
    color: white;
    background-color: #1d49ae;
    border: 1px solid gray;
    justify-content: space-between;
    position: relative;
    padding: 10px;
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      top: 0px;
      color: #ebe6e6;
    }
    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .prev-img {
      width: 120px;
      height: 80px;
      object-fit: contain;
    }
  }
}

.upload-docs-body {
  display: grid;
  // grid-template-columns: 80%;
  // padding-right: 60px;
  margin-top: 2em;
  @include respond-to("small") {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px;
  }
  .time-line-container {
    border-right: 1px solid gray;
  }
}
.select-restaurant-container {
  // margin: 10%;
  width: 80%;
  .selectContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  margin: 0 auto;
  .title {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 2em;
  }
}
.sub-label {
  color: #008a52;
  margin-bottom: 0.5em;
}
.basic-info {
  .selectContainer {
    display: flex;
  }
  .info-row {
    color: #008a52;
    padding: 10px 20px;
  }
  .value {
    color: black;
    // padding: 5px;
  }
}
.store-div {
  margin-top: 1em;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  grid-gap: 20px;
  margin-bottom: 2rem;
}
.upload-section {
  margin-top: 1em;
}
