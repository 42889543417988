.department-container {
  padding: 0.5em 3em 0.5em 3em;
  margin-top: 1rem;
  .body-table-cell {
    min-width: 10rem !important;
    word-break: break-all;
    padding: 0px !important;
  }
  .btn-con {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    word-break: break-all;
  }
  .rounded-btn {
    border-radius: 15px;
  }
}

.create-update-dept-con {
  padding: 2em;
  .cancel-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select-title {
    margin-bottom: 2px;
    font-size: small;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 20px;
    .modal-button {
      margin-top: 50px;
      border-radius: 10px;
    }
  }
}
