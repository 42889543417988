@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.login-background {
  // background-image: url("../../resources/images/login-image.png");
  background-image: url(http://restogro.com/wp-content/uploads/2019/12/BG-2.png),
    linear-gradient(180deg, #051d5b 72%, #051d5b 45%) !important;
  // background-repeat: repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 5rem;
  padding-left: 5rem;
  @include respond-to(small) {
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-con {
    background-color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 400px;
    height: 500px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    @include respond-to(small) {
      width: 320px;
      height: 400px;
      margin: 10px;
    }

    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
  }
  .powered-by {
    color: white;
    margin-top: 30px;
    margin-left: 20px;
    font-weight: lighter;
  }
  .white-logo {
    position: absolute;
    top: 40%;
    right: 60px;
  }
}
