@import '../../../../styles/responsive.scss';

.invoice-request-container {
  padding: 20px;
  max-width: 1140px;
  background-color: #f9f9f9;
  margin: auto;
  @include respond-to('small') {
    padding: 10px;
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 6px 0px;
    justify-content: space-between;
    background: white;
    margin-bottom: 10px;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .right-section {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @include respond-to('small') {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
  .invoice-request-details {
    .form-row {
      display: grid;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
}
