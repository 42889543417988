.lease-rent {
  .g-r-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 5px;
    height: auto;
  }
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  padding: 2px 2em;
  .header {
    padding-bottom: 0px;
  }
  .sel-item {
    width: 90%;
  }
  .total-tax {
    width: 70%;
    margin-right: 3em;
  }
  .mt10 {
    margin-top: -5px !important;
  }
  .search-p {
    margin: 0px;
    display: flex;
    align-items: center;
  }
}
