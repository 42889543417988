/** @format */

@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";
.main-setup-tab-container {
  min-height: 80vh;
}
.table-container {
  // max-width: 1440px;
  margin: auto;
  .create-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
  .title-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    .l-c {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
  }
  .date-filter-row {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r-c {
      display: flex;
      grid-gap: 20px;
      align-items: center;
    }
  }
}

.form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  row-gap: 20px;
  .category-grid {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;
  }
  .product-input {
    width: 100%;
    margin-bottom: 10px;
  }
}

.products-container {
  .main-product-grid {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    column-gap: 10px;
  }
  .main-search-field {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    .search-field {
      display: flex;
      align-items: center;
    }
    .main-search-icon {
      cursor: pointer;
    }
  }
  .main-category-item {
    grid-template-columns: 0.3fr 1fr;
    column-gap: 10px;
    align-items: center;
    display: grid;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #008a52;
  }
  .main-category-item:hover {
    cursor: pointer;
    color: white;
    background-color: #008a52;
  }
  .selected-main-category {
    cursor: pointer;
    color: white;
    background-color: #008a52;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  }
}

.products-category-container {
  .si-row {
    grid-template-columns: 1fr 1.5fr;
  }
  .flex-end {
    display: flex;
    justify-content: end;
  }
  .main-search-field {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    .search-field {
      display: flex;
      align-items: center;
    }
    .main-search-icon {
      cursor: pointer;
    }
  }
}

.ct-container {
  .si-row {
    grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr;
  }
  .main-search-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    margin-bottom: 10px;
    .complaint-type-field {
      min-width: 40%;
    }
    .search-field {
      display: flex;
      align-items: center;
    }
    .main-search-icon {
      cursor: pointer;
    }
  }
  .create-button {
    display: flex;
    justify-content: end;
  }
}

.premix-container,
.items-list-container,
.recipes-container {
  .si-row {
    grid-template-columns: 1fr 1.5fr 1fr 0.5fr 0.5fr;
  }
}
.items-list-container {
  .close-icon {
    display: flex;
    justify-content: flex-end;
  }
}
