.finance-invoice-details-container {
  .finance-invoice-details-header {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    .back-title {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .finance-left-section {
      display: flex;
      align-items: center;
      gap: 10px;
      .header-form-container {
        .info-section {
          display: flex;
          align-items: center;
          gap: 20px;
          .info-box {
            display: flex;
            flex-direction: column;
            gap: 10px;
            span:first-child {
              color: gray;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .right-section {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .invoice-detail-approval-stage {
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 10px;
    margin-top: 10px;
    .approval-details {
      background-color: white;
      min-height: 400px;
      padding: 10px;
    }
  }
  .form-container {
    padding: 10px;    
    background-color: white;
    .info-section {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      .info-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        span:first-child {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .add-additional-doc {
        max-width: 100%;
      }
    }
    .payment-details-btn {
      display: flex;
      justify-content: end;
      margin-right: 25px;
    }
  }
  .add-additional-doc {
    max-width: 100%;
  }
  .attachments-container {
    background-color: white;
    padding: 10px;
  }
}
