.BellcorpStudioLogo {
  bottom: 1em;
  right: 1em;
  font-size: small;
  z-index: 1;
  // padding-right: 1em;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  position: absolute;
  img {
    width: 120px;
    height: 50px;
    object-fit: contain;
  }
}
