.grn-approval-list {
  padding: 20px 0px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
  }
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .finance-bulk-btn {
    margin: 1rem auto;
    display: flex;
    justify-content: flex-end;
  }
}
