.approval-stage-details-container {
  .approval-stage-details-container {
    overflow-y: auto;
    max-height: 500px;
  }
}
.stage-details-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 5px;
  border-radius: 6px;
  &.approved {
    background-color: #1756f3;
  }
  &.warning {
    background-color: orange;
  }
  &.rejected {
    background-color: red;
  }
  &.reconsider {
    background-color: gray;
  }
  .stage-header {
    color: white;
    padding-bottom: 5px;
    font-size: 12px;
  }
  .stage-details {
    background-color: white;
    padding: 10px;
    position: relative;
    .info-section {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      margin-bottom: 10px;
      gap: 5px;
      font-size: 12px;
    }
  }
}
.stage-details-line {
  content: "";
  background-color: #c8c4c4;
  width: 5px;
  height: 40px;
  top: 10px;
  left: 50%;
  display: block;
  margin: 5px auto;
}
