.restaurant-form-container {
  padding: 10px;
  background-color: white;
  .select-restaurant-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    .subway-select__placeholder {
      text-align: right;
    }
  }
  .select-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .main-Header {
    background: #008a52;
    color: white;
    margin-bottom: 10px;
    padding: 10px;
  }
  .sub-header {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    background-color: lightgreen;
  }
  .input {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .save-and-cancel-button {
    display: flex;
    justify-content: end;
    margin-bottom: 50px;
    margin-right: 20px;
  }
  .warning-text {
    color: red;
  }
  .helper-text {
    color: red;
    margin: 0px;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
