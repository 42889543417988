@import "../../common-utilities/styles/variables.scss";
@import "../../common-utilities/styles/responsive.scss";
.main-container {
  background-color: $gray-bg;
  display: flex;
  height: 100vh;
  overflow: auto;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .main-right-container {
    background-color: $gray-bg;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
    height: 100vh;
  }
}
.mobile-header-container {
  width: -webkit-fill-available;
}
