@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";
.past-tickets-container {
  max-width: 1440px;
  margin: auto;
  padding: 20px;
  .styled-text-field {
    // background-color: wh;
    background-color: #ffffff;
  }
  margin-top: 1rem;
  .date-range-filter {
    margin-bottom: 20px;
  }
  @include respond-to("small") {
    background: $gray-bg;
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
  .card {
  }
}

/// view-ticket-container

.view-ticket-container {
  width: 906px;
  height: 100vh;
  background: #306fff;
  padding: 40px 24px;
  @include respond-to("small") {
    width: 100vw;
    padding: 0px;
  }
  .close-btn-title {
    display: flex;
    align-items: center;
    grid-gap: 44px;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
    @include respond-to("small") {
      font-size: 18px;
      justify-content: flex-start;
    }
  }
  .details-table {
    width: 758px;
    height: 879px;
    height: calc(100vh - 180px);
    background: #ffffff;
    border: 1px solid #e1e7ff;
    box-sizing: border-box;
    margin-left: 84px;
    margin-top: 33px;
    overflow: scroll;
    @include respond-to("small") {
      width: auto;
      height: auto;
      margin: 0px;
    }
    .data-row {
      display: grid;
      height: 54px;
      align-items: center;
      border-bottom: 1px solid #efefef;
      grid-template-columns: 276px 100%;
      padding: 10px;
      @include respond-to("small") {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      span:nth-child(2) {
        border-left: 1px solid #efefef;
        padding-left: 10px;
        height: 54px;
        display: flex;
        align-items: center;
        @include respond-to("small") {
          border: 0px;
          padding: 0px;
          height: auto;
        }
      }
    }
  }
  .free-services {
    width: auto;
    height: auto;
  }
  .service-row {
    display: grid;
    grid-template-columns: 100px 124px 100px;
    grid-gap: 20px;
    width: fit-content;
    background: white;
    padding: 4px 10px;
    font-size: 12px;
    align-items: center;
    border-bottom: 1px solid #ebe6e6;
  }
  .error {
    background: red;
    color: white;
  }
  .auto-height {
    height: auto !important;
    min-height: 54px;
  }
}
//ticket card for mobile
.mobile-view-navbar {
  background-color: white;
  height: auto;
  display: flex;
  .logo {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.1px;
    padding: 5px 0px 5px 20px;
  }
}
.ticket-container-mobile {
  max-width: 1000px;
  margin: auto;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  .search-field {
    width: 50%;
    background: white;
    border-radius: 5px;
  }
}
.card {
  max-width: 1000px;
  height: auto;
  border: 1px solid #c2d1f5;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;
  background-color: white;
  margin-bottom: 15px;
  .card-content {
    margin-bottom: 0px;
    padding: 0px 10px;
  }
  .ticket-id {
    color: #0c43c2;
  }
  .label {
    color: grey;
  }
  .value {
    color: black;
  }
}

.createTicketButtonContainer {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}
.search-and-create-ticket {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-list-con {
  background-color: #306fff;
  padding: 1em;
  border-radius: 0.5em;
}

.si-table-container .si-body .clickable.userClickable {
  cursor: pointer;
  color: #306fff;
  text-decoration: underline;
}
