@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.reports-container {
  max-width: 1440px;
  margin: auto;
  padding: 0 2rem;
  .searchField {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    @include respond-to("small") {
      flex-wrap: wrap;
    }
  }
  .downloadReportsbutton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
  .create-new {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;

    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 2em;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
  }

  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-built-lease-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-built-license-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-built-agreement-and-post-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-accquired-agreement-and-post-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-lease-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-license-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.mfa-rider {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-acquistion-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.fofo-lease-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.fofo-fa-agreement-upload {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .si-row.coco-built-lease-agreement-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-built-license-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-built-loi-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-lease-agreement-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-license-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.coco-acquired-acquistion-informational {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .si-row.fofo-lease-agreement-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .si-row.fofo-fa-agreement-informational {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
  .left-c {
    display: flex;
    grid-gap: 20px;
  }
  .back-container {
    display: flex;
  }
  .si-table-container {
    overflow-x: scroll;
  }
  .si-table-container .si-body .si-row {
    // min-height: 60px;
    word-break: break-all;
  }
}
.filter-con {
  // width: 19%;
  min-width: 15%;
}
.main-filter-con {
  display: flex;
  width: 100%;
  grid-gap: 1em;
  justify-content: space-between;
  @include respond-to("small") {
    // flex-direction: column;
    grid-gap: 0.5em;
  }
}
.search-btn {
  width: 15%;
}
.si-cell {
  font-size: small;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
