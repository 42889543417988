.cf-approval-dashboard {
  margin: auto;
  padding: 2em;
  margin-top: 2em;
  .filter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .pointer {
    cursor: pointer;
  }
}
