.budget-details-container {
  padding: 20px;
  .bd-header {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .bd-r-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .info-c {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .label {
        color: gray;
        font-size: 14px;
      }
      .value {
        font-style: bold;
        font-size: 16px;
      }
    }
    .budget-btns {
      display: flex;
      gap: 20px;
    }
  }
  .approvals-line-items {
    display: grid;
    grid-template-columns: 1fr;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .approval-details {
      background: white;
      margin-top: 41px;
      padding: 10px;
    }
  }
}
// .line-item-history-comments-container {
  
// }
.line-item-history-comments {
  word-break: break-all;
  display: flex;
  // gap: 10px;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
}
.comment-attachments-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}