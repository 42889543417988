@import "./responsive.scss";
@import "../packages/common-utilities/styles/variables.scss";
@font-face {
  font-family: "DMSans-Regular";
  src: url("../resources/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../resources/fonts/DMSans-Bold.ttf") format("truetype");
}

body {
  font-family: "DMSans-Regular";
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0px;
  background-color: $gray-bg !important;
}
.error-msg {
  color: red;
  margin-top: 10px;
  display: inline-block;
}
.si-hide-mobile {
  @include respond-to("small") {
    display: none !important;
  }
}
.si-hide-web {
  display: none !important;
  @include respond-to("small") {
    display: inline-block !important;
  }
}

.s-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #373f41;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #c7c9cb;
  border-radius: 10px;
}
