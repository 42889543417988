@import "../../../common-utilities/styles/responsive.scss";
.create-tickets {
  padding: 40px 60px;
  @include respond-to("small") {
    padding: 40px;
  }
  .back-container {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    @include respond-to(small) {
      grid-gap: 0px;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .create-ticket-form {
    padding-top: 40px;
    padding-left: 70px;
    .form-row {
      display: grid;
      grid-template-columns: 230px 465px;
      grid-gap: 70px;
      margin-bottom: 20px;
      @include respond-to("small") {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
      }
      .label {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #333333;
      }
      .sub-form {
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
      }
      .service-row {
        display: grid;
        grid-template-columns: 124px 124px 124px 50px;
        background: white;
        padding: 4px 10px;
        font-size: 12px;
        align-items: center;
        border-bottom: 1px solid #ebe6e6;
      }
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      padding-right: 90px;
      grid-gap: 20px;
    }
  }
}

.css-2613qy-menu {
  background-color: #1756f3 !important;
  box-shadow: #1756f3 !important;
  border-color: #1756f3 !important;
}

.danger-text {
  color: red;
}
