@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";
.ticket-details-container {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  @include respond-to("small") {
    flex-direction: column;
  }

  .middle-section {
    width: 100%;
  }
}

//basic-info-container

.basic-info-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 10px;
  @include respond-to("small") {
    flex-direction: column;
  }
  .tkt-id {
    .label {
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #f2f2f2;
    }
    .id {
      @extend .label;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    padding-top: 20px;
    .info-section {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
      padding-bottom: 10px;
      .value {
        font-size: 14px;
        font-weight: bold;
        background-color: white;
        color: black;
        padding: 5px;
      }
      .service-engineer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

///time-line-container

.time-line-info-container {
  padding: 20px;
  // width: 80%;
  background: white;
  padding-right: 0px;
  @include respond-to("small") {
    height: auto;
    padding: 10px;
    padding-top: 10px;
    width: 100%;
  }
  .inspection-date {
    padding-bottom: 18px;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    grid-gap: 20px;
  }
  .time-line {
    overflow: scroll;
    position: relative;
    height: 50vh;
    @include respond-to("small") {
      height: auto;
      padding: 10px;
    }
  }

  .tag-modal-body {
    color: black;
  }
  .showTags {
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }

  .line2 {
    background: #1d49ae;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 90%;
    line-height: 24px;
    padding: 2px 8px;
    word-wrap: break-word;
    letter-spacing: 0.2px;
    color: white;
  }
  .clickable {
    cursor: pointer;
  }
  .time-c-extra {
    min-height: 140px;
  }
}

// form-details-container
.form-details-container {
  min-width: 300px;
  padding: 20px;
  background: white;
  padding-left: 0px;
  @include respond-to("small") {
    height: auto;
    padding: 10px;
    padding-top: 10px;
  }
  .severity {
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
  }
  .comments-section {
    background: #e8e8e8;
    padding: 10px;
  }
  .action-form {
    padding: 20px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
  .buttons-container {
    display: flex;
    grid-gap: 10px;
    justify-content: center;
  }
  .drop-down-label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #1d49ae;
  }
  .add-comment-bts {
    display: flex;
    grid-gap: 20px;
    flex-direction: column;
  }
  .a002-buttons {
    padding-top: 10px;
  }
  .resolve-msg {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.si-select__menu {
  z-index: 2000 !important;
}
.si-select__control {
  border: 0px !important;
  background: lightgrey !important;
  color: white !important;
}
.css-tj5bde-Svg {
  color: black;
}

.pre-defect-code-stage {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}

.reassign-container {
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
  color: black;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-line {
  .time-line-c.attachmentColor a {
    color: white;
  }
}

.time-line {
  .time-line-c.one-attachment {
    min-height: 100px;
  }
}

.time-line {
  .time-line-c.two-attachment {
    min-height: 150px;
  }
}

.time-line {
  .time-line-c.three-attachment {
    min-height: 200px;
  }
}

.time-line {
  .time-line-c.four-attachment {
    min-height: 250px;
  }
}

.time-line {
  .time-line-c.five-attachment {
    min-height: 300px;
  }
}
.grid-con {
  display: flex;
  justify-content: space-between;
}
.left-section {
  // background: #1756f3;
  width: auto;
  margin: 20px;
  height: 100%;
  padding: 20px;
  // color: white;

  @include respond-to("small") {
    width: auto;
    height: 100%;
  }
}
