.subs-date-time-picker {
  .MuiInputBase-input {
    padding: 5px 10px;
    height: 20px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -12px;
  }
}
.person-history-con {
  .MuiFormLabel-root {
    z-index: 0 !important;
  }
  .custom_select__control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .custom_select__value-container {
    margin-top: -4px !important;
  }

  .custom_select__indicators {
    margin-top: -4px !important;
  }
  background-color: white;
  padding: 1em;
  .search-div {
    display: flex;
    align-items: center;
    padding-top: 2em;
    p {
      color: #008a52;
    }
  }
  .table-con {
    margin-top: 1em;
    .table-cell {
      color: white;
    }
  }
}
