.ar-con {
  padding: 0.5em 1em 0px 1em;
  .search-com {
    display: flex;
    margin-bottom: 1.5em;
    justify-content: space-between;
    .search-elememts-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 10px;
    }
  }
}
