.training-manual-home {
  padding: 0px 30px;
  margin: 10px;
  .main-header {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .main-filter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    column-gap: 20px;
  }
  .end-crt-btn {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    gap: 20px;
  }
  .training-manual-si-row {
    grid-template-columns: 1fr 1fr;
  }
  .main-document-list {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    margin-bottom: 20px;
    .main-document-card {
      cursor: pointer;
      .card-content {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        font-size: x-large;
      }
    }
  }

  .main-documents-table-container {
    // display: flex;
    // justify-content: space-between;
    .main-documents-table {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 20px;
      .details-con {
        display: inline-block;
        .con-div {
          display: grid;
          grid-template-columns: 42px 1fr;
          gap: 20px;
          padding-left: 10px;
          .title {
            width: 90%;
          }
        }
      }
      .apps-con {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 20px;
        flex-wrap: wrap;
      }
    }
    .pdf-preview-section {
      background-color: #fff;
      min-width: 600px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      .title {
        padding: 10px 5px;
      }
      .wrap {
        background-color: #bbbbbb;
        padding: 5px;
      }
      .react-pdf__Page {
        max-height: 600px;
        overflow-y: scroll;
      }
    }
    .si-pagination {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      .css-ug67gd-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        border-radius: 50%;
      }
    }
  }
  .category-container {
    .crt-btn {
      display: flex;
      justify-content: end;
      margin-bottom: 10px;
    }
  }
}

.policy-drawer {
  width: 70rem;
  height: inherit;
  padding: 2rem;
}
.pdf-container {
  display: flex;
  justify-content: center;
  user-select: none;
  .wrap {
    margin: auto 5rem;
  }
  .controls {
    margin: 20px;
    justify-content: center;
  }
}
@media print {
  .pdf-container {
    display: none;
  }
}
.react-pdf__Page__annotations {
  width: 0px !important;
  height: 0px !important;
}
.react-pdf__Page__textContent {
  display: none !important;
}
.pdf-btn-con {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.main-pdf-container {
  width: fit-content;
  margin-bottom: 25px;
  .confirm-policy {
    .i-agreeTxt {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    padding: 10px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    align-items: center;
  }
}
