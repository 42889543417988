
.budget-approval-details-container {
  padding: 20px;
  .bd-header {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .bd-r-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .info-c {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .label {
        color: gray;
        font-size: 14px;
      }
      .value {
        font-style: bold;
        font-size: 16px;
      }
    }
  }
  .approvals-line-items {
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .approval-details {
      background: white;
      margin-top: 41px;
      padding: 10px;
    }
  }
}


.approval-stage-details-container{
  .details-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.b-modal-form-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}